import React, { PureComponent, useState, useCallback, useEffect } from "react";
import Helmet from "react-helmet"
import IndexSPA from "../../components/IndexSPA"

const Index = (props) => {
   return (
      <>
        <Helmet
          title="第一輪 #機制執行 / 社宅公共藝術咖啡桌 / HOME TO ALL 我們也可以這樣生活 / 臺北社宅藝術計劃線上展覽"
          meta={[
            {
              name: `description`,
              content: "公共藝術離開美術館、畫廊，進入都市中各種真實生活場景實踐，面對不同背景、階級、需求的人們，常被賦予各種不同的任務以及使命。在台北市社宅的社會運動之中，被專業界期許扮演橋樑，以凝聚社區意識、作為當地社區的溝通觸媒。",
            },
            {
              property: `og:title`,
              content: "第一輪 #機制執行 / 社宅公共藝術咖啡桌 / HOME TO ALL 我們也可以這樣生活 / 臺北社宅藝術計劃線上展覽",
            },
            {
              property: `og:description`,
              content: "公共藝術離開美術館、畫廊，進入都市中各種真實生活場景實踐，面對不同背景、階級、需求的人們，常被賦予各種不同的任務以及使命。在台北市社宅的社會運動之中，被專業界期許扮演橋樑，以凝聚社區意識、作為當地社區的溝通觸媒。",
            },
            {
              property: `og:image`,
              content: 'https://online.home-to-all.com' + require('../../images/blog/post_2_3_1.jpg')
            }
          ]}
        >
        </Helmet>
         <IndexSPA />
      </>
   )
 };
 
 
 export default Index;